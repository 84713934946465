import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
//border-bottom : none;

import "../src/styles/Tabs.css"
import Tab1 from './components/Tab1'
import Tab2 from "./components/Tab2"
import Tab3 from "./components/Tab3"
import Tab4 from "./components/Tab4"
import Tab5 from "./components/Tab5"
import Tab6 from "./components/Tab6"
import Tab7 from "./components/Tab7"
import Tab8 from "./components/Tab8"
import Tab9 from "./components/Tab9"
import Tab10 from "./components/Tab10"
import Tab11 from "./components/Tab11"
import Tab12 from "./components/Tab12"
import Tab13 from "./components/Tab13"
import Tab14 from "./components/Tab14"

const TabsComp = ({ toggle, setToggle }) => {

  return (
    <>

      <Tabs>
      <TabList className={`flex placeTabs-hold spacing-5`}>
          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/96f700f2-e9fb-4580-95ee-11b2e37cd943/256x192.jpg' alt='BucketList Hotels' />
              <p className='text-gray-400 text-sm pt-2'>BucketList Hotels</p>

            </div>

          </Tab>

          <Tab className='tabImg md:pr-0 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/838be8a6-ded5-4334-9de0-f236d39a15c2/256x192.jpeg' alt='New Openings' />
              <p className='text-gray-400 text-sm pt-2'>New Openings</p>

            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/a731b716-3e34-46e1-9a91-9369c1cde693/256x192.jpg' alt='Infinity Pools' />
              <p className='text-gray-400 text-sm pt-2'>Infinity Pools</p>

            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className=''  src='https://pkslist.azureedge.net/media/images/2/c84670b2-3327-42ce-a2dd-d9db723417d0/256x192.png' alt='Spa & Wellness' />
              <p className='text-gray-400 text-sm pt-2'>Spa & Wellness</p>

            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/ad973009-94d2-4651-a4bd-17b1b2afd37d/256x192.jpg' alt='Wildlife Encounters"' />
              <p className='text-gray-400 text-sm pt-2'>Wildlife Encounters"</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className=''  src='https://pkslist.azureedge.net/media/images/2/6d48ffbe-cb0b-419b-b95b-66b91a2b1667/256x192.jpg' alt='Fairway Dreams' />
              <p className='text-gray-400 text-sm pt-2'>Fairway Dreams"</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/22946873-3283-441e-af31-9c6b4cc35703/256x192.jpg' alt='Beach Paradise' />
              <p className='text-gray-400 text-sm pt-2'>Beach Paradise</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/31b925cd-dc98-430c-93f7-39406f5a9d46/256x192.jpg' alt='Maldives Top 10' />
              <p className='text-gray-400 text-sm pt-2'>Maldives Top 10</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className=''  src='https://pkslist.azureedge.net/media/images/2/72e1cc4d-65eb-4c5f-a986-e45ecff1596c/256x192.jpg' alt='New York Top 10' />
              <p className='text-gray-400 text-sm pt-2'>NY Top 10</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className=''  src='https://pkslist.azureedge.net/media/images/2/6a5625cd-66d9-4b4e-ab1d-3fd3711a43ed/256x192.jpg' alt='Paris Top 10' />
              <p className='text-gray-400 text-sm pt-2'>Paris Top 10</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className=''  src='https://pkslist.azureedge.net/media/images/2/90906a5f-ad06-4005-bc71-bf80395e44d7/256x192.jpg' alt='London Top 10' />
              <p className='text-gray-400 text-sm pt-2'>London Top 10</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/47494614-0c48-4eb8-9861-2419c890cd9a/256x192.jpg' alt='Rustic Elegance' />
              <p className='text-gray-400 text-sm pt-2'>Rustic Elegance</p>
            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className='' src='https://pkslist.azureedge.net/media/images/2/bbc81c3a-b4ca-42f1-ae8c-7188a42528ff/256x192.jpg' alt='Winter Wonderland' />
              <p className='text-gray-400 text-sm pt-2'>Winter Wonderland</p>

            </div>

          </Tab>

          <Tab className='tabImg md:pr-1 pr-0'>
          <div className='w-18'>
              <img className=''  src='https://pkslist.azureedge.net/media/images/2/40771610-2ca6-499e-a95f-e4c3baa18bfe/256x192.jpg' alt='Extremely Remote' />
              <p className='text-gray-400 text-sm pt-2'>Extremely Remote</p>

            </div>

          </Tab>


        </TabList>



        <TabPanels>
          <TabPanel className="tab-content"> <Tab1 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab2 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab3 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab4 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab5 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab6 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab7 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab8 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab9 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab10 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab11 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab12 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab13 pushDown={toggle} />  </TabPanel>
          <TabPanel className="tab-content"> <Tab14 pushDown={toggle} />  </TabPanel>
        </TabPanels>

      </Tabs >

    </>
  )
}

export default TabsComp
//         <TabList className={toggle === true? "tabs-hold-pushDown" : "tabs-hold"}>

